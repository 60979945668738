import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';

export enum BrandColor {
  Brand100 = '#e70868',
  Brand200 = '#a3e0e4',
  Brand300 = '#7ed4d9',
  Brand400 = '#2e3093',
  Brand500 = '#2e3093',
  Brand600 = '#e70868',
  Brand700 = '#153a3c'
}

export class Color {
  public static readonly Primary = BrandColor.Brand400;

  public static readonly Secondary = BrandColor.Brand100;

  public static readonly Tertiary = BrandColor.Brand100;

  public static readonly Transparent = 'transparent';

  public static readonly White = '#FFFFFF';

  public static readonly Gray100 = '#F9F9FA';

  public static readonly Gray200 = '#F2F4F5';

  public static readonly Gray300 = '#E7ECEF';

  public static readonly Gray400 = '#AFBCC3';

  public static readonly Gray500 = '#899DA9';

  public static readonly Gray600 = '#5E7887';

  public static readonly Gray700 = '#3A4E59';

  public static readonly Gray800 = BrandColor.Brand600;

  public static readonly Gray900 = BrandColor.Brand500;

  public static readonly Alert = '#DC2729';

  public static readonly Warning = '#F5A623';

  public static readonly Success = '#1ABC9C';

  public static readonly Background = '#FFFFFF';
}

export enum Spacing {
  XNano = '0.3rem',
  Nano = '0.6rem',
  Small = '0.9rem',
  SemiSmall = '1.2rem',
  Medium = '1.8rem',
  SemiLarge = '2.7rem',
  Large = '3.6rem',
  XLarge = '7.5rem',
  XXLarge = '9rem'
}

export enum FontFamily {
  Primary = 'Poppins'
}

export enum FontWeight {
  Regular = 400,
  Medium = 500,
  Bold = 600
}

export enum LineHeight {
  XSmall = '1em',
  Small = '1.25em',
  Medium = '1.4em',
  Large = '1.6em'
}

export enum FontSize {
  XSmall = '0.75rem',
  Small = '0.875rem',
  Medium = '1rem',
  Large = '1.25rem',
  XLarge = '1.6875rem',
  XXLarge = '2rem'
}

export enum Radius {
  Small = '0.15rem',
  Medium = '0.3rem',
  Large = '0.6rem'
}

export enum Breakpoint {
  XSmall = '575px',
  Small = '767px',
  Medium = '991px',
  Large = '1199px',
  XLarge = '1200px'
}

export enum Transition {
  Fast = '0.3s',
  Slow = '1s'
}

export enum Shadow {
  Primary = '0px 6px 12px rgba(67, 95, 112, 0.05), 0px 2px 6px rgba(94, 120, 135, 0.15);'
}
