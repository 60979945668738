import React, { useState } from 'react';

import { Link } from 'gatsby';

import { Button } from '@atomic/atm.button';
import { Grid, Row } from '@atomic/atm.grid';
import { NavigationLink } from '@atomic/atm.scroll-link';

import {
  MenuDesktopStyled,
  NavigationContentStyled,
  LogoWrapperStyled,
  MenuContentStyled,
  BtnHamburguerStyled
} from './header.component.style';

interface HeaderProps {
  // eslint-disable-next-line no-undef
  data: GatsbyTypes.MarkdownRemarkFrontmatterHeader;
}

export const Header: React.FC<HeaderProps> = ({ data }) => {
  const [stickyNav, setStickyNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isSticky = () => {
    if (window.scrollY >= 40) {
      setStickyNav(true);
    } else {
      setStickyNav(false);
    }
  };

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', isSticky);
  }

  return (
    <MenuDesktopStyled
      className={`${stickyNav ? 'active' : ''} ${isOpen ? 'open' : ''}`}
    >
      <Grid>
        <Row>
          <MenuContentStyled xs={12} sm={12} md={12} lg={12} xl={12}>
            <LogoWrapperStyled onClick={() => window.scrollTo(0, 0)}>
              <img src={data.logo} alt="Logo" />
            </LogoWrapperStyled>

            <BtnHamburguerStyled
              onClick={() => setIsOpen(!isOpen)}
              className={`${isOpen ? 'active' : ''}`}
            >
              Menu
              <span />
            </BtnHamburguerStyled>

            <NavigationContentStyled className={`${isOpen ? 'active' : ''}`}>
              <NavigationLink to="/#content-section">
                Como Funciona
              </NavigationLink>

              <NavigationLink to="/#testimonials">Depoimentos</NavigationLink>

              <NavigationLink to="/#how-it-works">Programa</NavigationLink>

              <Button
                kind="tertiary"
                onClick={() => window.open('https://app.unikordsa.com.br')}
              >
                Fazer Login
              </Button>
              {process.env.GATSBY_SHOW_PLAN === 'true' && (
                <Link to="/#plans">
                  <Button kind="secondary">Ver planos</Button>
                </Link>
              )}
            </NavigationContentStyled>
          </MenuContentStyled>
        </Row>
      </Grid>
    </MenuDesktopStyled>
  );
};
